import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

interface Props {
  label: string
  logo: React.ReactNode
  onClick?: () => void
}

export const LabelWithLogo: React.FC<Props> = ({ label, logo, onClick }) => {
  const styles = useStyles()
  return (
    <div className={styles.container} onClick={onClick}>
      {logo}
      <Typography variant="inherit" component="div" className={styles.label}>
        {label}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(1),
    alignItems: 'center',
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
  },
}))
