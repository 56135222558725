import { accessToken, AuthProvider, createLocalAuthStorage } from '@airfocusio/react-auth-provider'
import { HttpProvider } from '@airfocusio/react-http-provider'
import axios from 'axios'
import queryString from 'query-string'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { App } from './App'
import * as serviceWorker from './serviceWorker'

const Index: React.FC = () => {
  const http = React.useRef(
    axios.create({
      paramsSerializer: queryString.stringify,
    })
  )
  const httpAccessToken = React.useRef(
    axios.create({
      paramsSerializer: queryString.stringify,
    })
  )
  const authStorage = React.useMemo(() => createLocalAuthStorage(), [])
  const authUrl = '/api/auth/oauth/access_token'
  return (
    <AuthProvider<{ refreshToken: string }>
      http={http.current}
      update={async (credentials) => {
        const query = queryString.parse(window.location.search)
        const explicitRefreshToken = Array.isArray(query.refreshToken) ? query.refreshToken[0] : query.refreshToken
        const refreshToken = explicitRefreshToken || credentials?.data.refreshToken
        if (refreshToken) {
          const tokenResponse = await accessToken(httpAccessToken.current, authUrl, {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: 'web',
          })
          return (
            tokenResponse && {
              accessToken: tokenResponse.access_token,
              expiresAt: Date.now() + tokenResponse.expires_in * 1000,
              data: {
                refreshToken: tokenResponse.refresh_token,
              },
            }
          )
        } else {
          return undefined
        }
      }}
      customStorage={authStorage}
    >
      <HttpProvider http={http.current}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HttpProvider>
    </AuthProvider>
  )
}

ReactDOM.render(<Index />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
