import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

import dockerIcon from './docker.svg'
import helmIcon from './helm.svg'
import htmlIcon from './html.svg'
import mavenIcon from './maven.svg'
import npmIcon from './npm.svg'

interface Props {
  type: string
  onClick?: () => void
}

export const RegistryLogo: React.FC<Props> = ({ type, onClick }) => {
  const styles = useStyles()
  switch (type) {
    case 'docker':
      return <img src={dockerIcon} alt="Docker" className={styles.icon} onClick={onClick} />
    case 'maven':
      return <img src={mavenIcon} alt="Maven" className={styles.icon} onClick={onClick} />
    case 'npm':
      return <img src={npmIcon} alt="NPM" className={styles.icon} onClick={onClick} />
    case 'helm':
      return <img src={helmIcon} alt="Helm" className={styles.icon} onClick={onClick} />
    case 'html':
      return <img src={htmlIcon} alt="HTML" className={styles.icon} onClick={onClick} />
    default:
      return <div className={styles.emptyIcon} onClick={onClick} />
  }
}

const useStyles = makeStyles(() => ({
  icon: {
    display: 'block',
    width: 24,
    height: 24,
    borderRadius: 4,
  },
  emptyIcon: {
    display: 'block',
    width: 24,
    height: 24,
    borderRadius: 4,
    backgroundColor: 'silver',
  },
}))
