import CssBaseline from '@material-ui/core/CssBaseline'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

import LayoutView from './views/LayoutView/LayoutView'

export const App: React.FC = () => {
  useStyles()
  return (
    <React.Fragment>
      <CssBaseline />
      <LayoutView />
    </React.Fragment>
  )
}

const useStyles = makeStyles(() => ({
  '@global': {
    html: {
      margin: 0,
      padding: 0,
    },
    body: {
      margin: 0,
      padding: 0,
    },
  },
}))
