import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { Route, Routes, useNavigate } from 'react-router'

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary'
import ErrorView from '../ErrorView/ErrorView'
import MessageView from '../MessageView/MessageView'
import { SideMenu } from './SideMenu'
import { TopBar } from './TopBar'

const HomeView = React.lazy(() => import('../HomeView/HomeView'))
const PackageView = React.lazy(() => import('../PackageView/PackageView'))
const VersionView = React.lazy(() => import('../VersionView/VersionView'))
const ApiKeysView = React.lazy(() => import('../ApiKeysView/ApiKeysView'))
const ApiKeyAccessLogView = React.lazy(() => import('../ApiKeyAccessLogView/ApiKeyAccessLogView'))

const LayoutView: React.FC = () => {
  const navigate = useNavigate()
  const styles = useStyles()
  const [sideMenuOpen, setSideMenuOpen] = React.useState(false)
  return (
    <React.Fragment>
      <TopBar onMenuButtonClick={() => setSideMenuOpen(true)} />
      <div className={styles.content}>
        <ErrorBoundary renderError={(error, reset) => <ErrorView error={error} onRequestReset={reset} />}>
          <React.Suspense fallback={null}>
            <Routes>
              <Route index element={<HomeView />} />
              <Route path="/package/:registryId/:packageName" element={<PackageView />} />
              <Route path="/package/:registryId/:packageName/:versionName" element={<VersionView />} />
              <Route path="/settings/api-keys" element={<ApiKeysView />} />
              <Route path="/settings/api-keys/:apiKeyId/access-log" element={<ApiKeyAccessLogView />} />
              <Route
                index
                element={() => (
                  <MessageView
                    title="Not found"
                    message="The requested page could not be found."
                    footer={
                      <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                        Go back
                      </Button>
                    }
                  />
                )}
              />
            </Routes>
          </React.Suspense>
        </ErrorBoundary>
      </div>
      <SideMenu open={sideMenuOpen} onChangeOpen={setSideMenuOpen} />
    </React.Fragment>
  )
}

export default LayoutView

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(3),
  },
}))
