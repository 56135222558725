import { AxiosInstance } from 'axios'
import dayjs from 'dayjs'

import { halPageResourceFromJSON, halResourceFromJSON, idLookupFromJSON } from './HalResource'
import { Permission } from './Permission'
import { registryFromJSON } from './Registry'

export interface Package {
  id: string
  registryId: string
  name: string
  size: number
  showUrl?: string
  permission?: Permission
  createdAt: dayjs.Dayjs
  updatedAt: dayjs.Dayjs
}

export function packageFromJSON(js: any): Package {
  return {
    ...js,
    createdAt: dayjs(js.createdAt),
    updatedAt: dayjs(js.updatedAt),
  }
}

export interface PermissionsEntry {
  packageId: string
  userId: string
  permission?: Permission
}

export function permissionsEntryFromJSON(js: any): PermissionsEntry {
  return {
    ...js,
  }
}

export function permissionsEntryToJSON(o: PermissionsEntry): any {
  return {
    ...o,
  }
}

export const listPackages = async (http: AxiosInstance, query: string | undefined, from: number, limit: number) => {
  const res = await http.get('/api/packages', {
    params: {
      query,
      from,
      limit,
    },
  })
  return halPageResourceFromJSON(res.data, packageFromJSON, {
    registries: idLookupFromJSON(registryFromJSON),
  })
}

export const getPackage = async (http: AxiosInstance, registryId: string, packageName: string) => {
  const res = await http.get(`/api/registries/${registryId}/packages/${encodeURIComponent(packageName)}`)
  return halResourceFromJSON(res.data, packageFromJSON, {
    registry: registryFromJSON,
  })
}

export const deletePackage = async (http: AxiosInstance, registryId: string, packageName: string) => {
  await http.delete(`/api/registries/${registryId}/packages/${encodeURIComponent(packageName)}`)
}

export const bulkPackagesDelete = async (http: AxiosInstance, packageIds: string[]) => {
  await http.post('/api/bulk/packages/delete', { packageIds })
}

export const bulkPackagesGetPermissions = async (http: AxiosInstance, packageIds: string[]) => {
  const res = await http.post('/api/bulk/packages/get-permissions', { packageIds })
  return halPageResourceFromJSON(res.data, permissionsEntryFromJSON, {
    users: idLookupFromJSON((username) => username as string),
  })
}

export const bulkPackagesSetPermissions = async (http: AxiosInstance, permissions: PermissionsEntry[]) => {
  await http.post('/api/bulk/packages/set-permissions', { permissions: permissions.map(permissionsEntryToJSON) })
}
