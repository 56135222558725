import { useAuth } from '@airfocusio/react-auth-provider'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'
import { useNavigate } from 'react-router'

import { TopBarSearchInput } from './TopBarSearchInput'
import { homeUrl } from './urls'

interface Props {
  onMenuButtonClick: () => void
}

export const TopBar: React.FC<Props> = ({ onMenuButtonClick }) => {
  const navigate = useNavigate()
  const styles = useStyles()
  const { credentials } = useAuth()
  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton
          edge="start"
          className={styles.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => onMenuButtonClick()}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={styles.title} onClick={() => navigate(homeUrl)}>
          {window.location.hostname}
        </Typography>
        <TopBarSearchInput />
        {!credentials && (
          <Button
            color="inherit"
            onClick={() => {
              window.location.href = '/api/auth/oauth/github'
            }}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))
