export interface Registry {
  id: string
  type: string
}

export function registryFromJSON(js: any): Registry {
  return {
    ...js,
  }
}
